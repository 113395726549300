import { Component } from 'react';
import PropTypes from 'prop-types';

import { handleError } from '../../utils';

export class ErrorBoundary extends Component {
  constructor() {
    super();

    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    const { handleNotificationMessage } = this.props;

    this.setState({ hasError: true });

    handleError(error, handleNotificationMessage, errorInfo);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    return !hasError && children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  handleNotificationMessage: PropTypes.func.isRequired
};
