import { BASE_DOMAIN } from './environment';

const [subdomain] = window.location.hostname.split('.');

const localCMSDomain = (() => {
  const CMSDomain = window.localVariables?.CMS_DOMAIN;

  if (CMSDomain) {
    const [protocol] = CMSDomain.split('://');
    const [, CMSBaseDomain] = CMSDomain.split(/\.(.+)/u);

    // Inject the current subdomain so it works for all sites.
    return `${protocol}://${subdomain}.${CMSBaseDomain}`;
  }

  return false;
})();

const WEBSITE = localCMSDomain ? `${localCMSDomain}` : `https://${subdomain}.${BASE_DOMAIN}`;

// Auth
const authUrl = `${window.localVariables?.AUTH_URL || `https://auth.${BASE_DOMAIN}`}/o/`;
const getAuthURL = schoolId => `${authUrl}${schoolId}/authorize/`;
const LOGOUT = `${WEBSITE}/accounts/logout/redir/next=${window.location.origin}/#send-message`;
const REVOKE_TOKEN_URL = `${authUrl}revoke-token/`;

// CMS
const HELP_AREA_CONTACTS = `${WEBSITE}/management/help/categories/using-the-school-app/`;

// Comms Hub
const SEND_MESSAGE = '/send-message';
const SENT_MESSAGES = '/sent-messages';

// API
const apiURL = `${window.localVariables?.API_GATEWAY_URL || `https://app.${BASE_DOMAIN}`}/api/v1/`;

const apiQueryParams = siteId => `?site_id=${siteId}&camelize=true`;

const API_MESSAGES = `${apiURL}messages/`;
const API_MESSAGES_V2 = `${apiURL}v2/messages/`;
const API_SEND_METHODS = `${API_MESSAGES}send-methods/`;
const API_SEND_TO_OPTIONS = `${API_MESSAGES}send-to-options/`;
const API_SITE_INFORMATION = `${API_MESSAGES_V2}subdomains/${subdomain}/?camelize=true`;
const getContactsApiURL = (siteId, nominated) =>
  `${API_MESSAGES}contacts/${apiQueryParams(siteId)}${nominated ? '&nominated=true' : ''}`;
const getDeliveryStatusesApiURL = (messageId, siteId) =>
  // The API returns 1000 statuses by default and 2000 at max. This can cause issues in cases where
  // we have more than 2000 recipients and we should probably consider pagination.
  `${API_MESSAGES_V2}${messageId}/delivery-statuses/${apiQueryParams(siteId)}&limit=2000`;

function getMessageApiURL(siteId, reportId) {
  const reportIdPathParam = reportId ? `${reportId}/` : '';

  return `${API_MESSAGES_V2}${reportIdPathParam}${apiQueryParams(siteId)}`;
}

export {
  API_SEND_METHODS,
  API_SEND_TO_OPTIONS,
  API_SITE_INFORMATION,
  getAuthURL,
  getContactsApiURL,
  getDeliveryStatusesApiURL,
  getMessageApiURL,
  HELP_AREA_CONTACTS,
  LOGOUT,
  REVOKE_TOKEN_URL,
  SEND_MESSAGE,
  SENT_MESSAGES,
  WEBSITE
};
