const { hostname } = window.location;
const [, baseDomain] = hostname.split('.comms.');

const ENVIRONMENT = (() => {
  const domain = '-primarysite.net';

  if (hostname.includes(`secure${domain}`)) {
    return 'production';
  }

  if (hostname.includes(domain)) {
    return baseDomain.replace(domain, '');
  }

  return 'local';
})();

const authClientIds = {
  local: window.localVariables?.AUTH_CLIENT_ID,
  preprod: 'iNbz1ehhoX0lxeDHVZgz5xULwmeR5lMxsNV8z9uC',
  production: 'o8mul6mGgFcm5ZGcS0PmJmO1mgEp10iszsQ9Qj8D',
  stage: 'j1shr45pLKWweOKFLTrxDgEJ3zewRGLGxAn51UJR',
  test: '0q7yNVEwM2vn3I70kGih8yjEBZA2UGjMcqsEXalb'
};

const AUTH_CLIENT_ID = authClientIds[ENVIRONMENT];
const BASE_DOMAIN = baseDomain + (ENVIRONMENT === 'local' ? ':8443' : '');

export { AUTH_CLIENT_ID, BASE_DOMAIN, ENVIRONMENT };
