import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AdminMenu, Header, NotificationMessage } from 'component-library/components';

import { dynamicImport } from '../../utils';
import { SEND_MESSAGE, SENT_MESSAGES, WEBSITE } from '../../urls';
import { ErrorBoundary } from '../ErrorBoundary';

import '../../styles/app.css';

import styles from './AppShell.css';

export class AppShell extends Component {
  constructor() {
    super();

    this.state = {};

    this.routes = [
      {
        component: dynamicImport(
          // eslint-disable-next-line no-inline-comments
          () => import(/* webpackChunkName: "SendMessage" */ '../SendMessage'),
          'SendMessage'
        ),
        name: 'SendMessage',
        title: 'Send Message',
        url: SEND_MESSAGE
      },
      {
        component: dynamicImport(
          // eslint-disable-next-line no-inline-comments
          () => import(/* webpackChunkName: "SentMessages" */ '../SentMessages'),
          'SentMessages'
        ),
        name: 'SentMessages',
        title: 'Sent Messages',
        url: SENT_MESSAGES
      },
      {
        component: dynamicImport(
          // eslint-disable-next-line no-inline-comments
          () => import(/* webpackChunkName: "NoMatch" */ '../NoMatch'),
          'NoMatch'
        ),
        name: 'NoMatch',
        title: 'Page Not Found'
      }
    ];

    const managementUrl = `${WEBSITE}/management/`;

    this.menuConfig = {
      logoUrl: WEBSITE,
      menuItems: [
        {
          icon: 'home',
          title: 'Home',
          url: WEBSITE
        },
        {
          icon: 'communication',
          items: [
            {
              icon: 'notifications',
              items: [
                {
                  icon: 'send',
                  title: this.routes[0].title,
                  url: this.generateHashUrl(this.routes[0].url)
                },
                {
                  icon: 'envelope',
                  title: this.routes[1].title,
                  url: this.generateHashUrl(this.routes[1].url)
                }
              ],
              title: 'App Messages',
              url: this.generateHashUrl(this.routes[0].url)
            },
            {
              icon: 'addressBook',
              title: 'Contacts',
              url: `${managementUrl}contacts/pupils/`
            }
          ],
          title: 'Communication'
        },
        {
          icon: 'help',
          title: 'Help',
          url: `${managementUrl}help/sections/quick-start-guides/`
        },
        {
          icon: 'user',
          items: [
            {
              icon: 'logOut',
              title: 'Log out',
              url: '#logout'
            }
          ],
          title: 'User'
        }
      ]
    };

    this.handleNotificationMessage = this.handleNotificationMessage.bind(this);
    this.renderComponent = this.renderComponent.bind(this);
    this.setDocumentTitle = this.setDocumentTitle.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { notificationMessage } = this.state;
    const {
      location: { pathname }
    } = this.props;

    if (notificationMessage && prevProps.location.pathname !== pathname) {
      this.handleNotificationMessage();
    }
  }

  generateHashUrl(url) {
    return `#${url.replace('/', '')}`;
  }

  handleNotificationMessage(notificationType, notificationMessage) {
    this.setState({ notificationMessage, notificationType });
  }

  renderComponent() {
    const { handleUnauthorisedRequest, hasMISIntegration, siteId, siteName } = this.props;
    const { notificationMessage } = this.state;

    return this.routes.map(route => (
      <Route
        key={route.url || 'no-match'}
        path={route.url}
        render={routeProps => {
          const RouteComponent = route.component;

          this.setDocumentTitle(route.title);

          return (
            <Suspense fallback={null}>
              <RouteComponent
                handleNotificationMessage={this.handleNotificationMessage}
                handleUnauthorisedRequest={handleUnauthorisedRequest}
                hasMISIntegration={hasMISIntegration}
                notificationMessage={notificationMessage}
                setDocumentTitle={this.setDocumentTitle}
                siteId={siteId}
                siteName={siteName}
                {...routeProps}
              />
            </Suspense>
          );
        }}
      />
    ));
  }

  setDocumentTitle(pageName) {
    const { siteName } = this.props;

    document.title = `${pageName} — App Messages | ${siteName}`;
  }

  render() {
    const {
      location: { pathname },
      siteName
    } = this.props;
    const { notificationMessage, notificationType } = this.state;

    return (
      <>
        <AdminMenu config={this.menuConfig} />

        <Header
          navItems={[
            {
              active: pathname === SEND_MESSAGE,
              url: this.generateHashUrl(SEND_MESSAGE),
              value: 'Send Message'
            },
            {
              active: pathname.indexOf(SENT_MESSAGES) === 0,
              url: this.generateHashUrl(SENT_MESSAGES),
              value: 'Sent Messages'
            }
          ]}
          schoolName={siteName}
          title="App Messages"
        />

        <NotificationMessage
          handleNotification={this.handleNotificationMessage}
          message={notificationMessage}
          type={notificationType}
        />

        <main className={styles.appShell}>
          <ErrorBoundary handleNotificationMessage={this.handleNotificationMessage}>
            <Switch>
              <Redirect exact={true} from="/" to="send-message" />
              {this.renderComponent()}
            </Switch>
          </ErrorBoundary>
        </main>
      </>
    );
  }
}

AppShell.propTypes = {
  handleUnauthorisedRequest: PropTypes.func.isRequired,
  hasMISIntegration: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  siteId: PropTypes.number.isRequired,
  siteName: PropTypes.string.isRequired
};
