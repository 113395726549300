import React, { StrictMode } from 'react';
import { render } from 'react-dom';
import { HashRouter, Route } from 'react-router-dom';
import { configureScope, init } from '@sentry/browser';

import { ENVIRONMENT } from '../environment';

import { PrivateRoute } from './PrivateRoute';

export function renderApp() {
  init({
    dsn: 'https://8b4eb9494b4346b1af97492a424ecccb@sentry.io/1292945',
    environment: ENVIRONMENT
  });

  // We need to set the URL explicitly otherwise Sentry doesn't add the hash part to it. We don't
  // want to send any sensitive data from the auth process though.
  if (!window.location.href.includes('access_token')) {
    configureScope(scope => {
      scope.setTag('url', window.location.href);
    });
  }

  render(
    <StrictMode>
      <HashRouter hashType="noslash">
        <Route path="/" render={routeProps => <PrivateRoute routeProps={routeProps} />} />
      </HashRouter>
    </StrictMode>,
    document.querySelector('.root')
  );
}

renderApp();
