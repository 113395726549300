import { captureException } from '@sentry/browser';

export function handleError(error, handleNotificationMessage, errorInfo) {
  if (handleNotificationMessage) {
    handleNotificationMessage('failure', 'An error occurred when trying to load this page.');
  }

  captureException(error, scope => {
    scope.setExtras(errorInfo);

    return scope;
  });
}
