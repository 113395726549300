export function mapContactsToPupils(contacts) {
  const pupilIds = [];

  return (
    contacts &&
    contacts.reduce(
      (
        pupils,
        { className, email, mobile, name: recipientName, nominated, pupilId, statuses, year }
      ) => {
        const pupilIndex = pupilIds.indexOf(pupilId);
        const separatorIndex = recipientName.indexOf(' - ');
        const contactName = recipientName.substr(separatorIndex + 3);
        const pupilName = recipientName.substr(0, separatorIndex);
        const valueWithFallback = value => value || '—';

        if (pupilIndex !== -1) {
          const pupil = pupils[pupilIndex];
          const generateData = (dataName, sortValue) => ({
            displayName: valueWithFallback(dataName),
            rowSpan: pupil.length,
            sortValue: sortValue || valueWithFallback(dataName)
          });

          return [
            ...pupils.slice(0, pupilIndex),
            [
              pupil[0],
              {
                ...pupil[1],
                class: generateData(className),
                pupilName: generateData(pupilName),
                year: generateData(year)
              },
              ...pupil.slice(2),
              {
                contactDetails: {
                  email: valueWithFallback(email),
                  mobile: valueWithFallback(mobile)
                },
                contactName,
                nominated,
                rowId: pupil.length,
                statuses
              }
            ],
            ...pupils.slice(pupilIndex + 1)
          ];
        }

        pupilIds.push(pupilId);

        return [
          ...pupils,
          [
            pupilId,
            {
              class: valueWithFallback(className),
              contactDetails: {
                email: valueWithFallback(email),
                mobile: valueWithFallback(mobile)
              },
              contactName,
              nominated,
              pupilName,
              rowId: 1,
              statuses,
              year: valueWithFallback(year)
            }
          ]
        ];
      },
      []
    )
  );
}
