/* eslint-disable import/max-dependencies */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import ClientOauth2 from 'client-oauth2';
import Cookies from 'cookies-js';
import { InformationPage, Link } from 'component-library/components';
import { fetchWrapper } from 'component-library/utilities';

import { API_SITE_INFORMATION, getAuthURL, LOGOUT, REVOKE_TOKEN_URL, WEBSITE } from '../../urls';
import { handleError } from '../../utils';
import { AUTH_CLIENT_ID } from '../../environment';
// eslint-disable-next-line import/max-dependencies
import { AppShell } from '../AppShell';

import styles from './PrivateRoute.css';

export class PrivateRoute extends Component {
  constructor() {
    super();

    this.state = {};

    this.getTokenError = this.getTokenError.bind(this);
    this.getTokenSuccess = this.getTokenSuccess.bind(this);
    this.handleUnauthorisedRequest = this.handleUnauthorisedRequest.bind(this);
    this.initiateAuth = this.initiateAuth.bind(this);
    this.loadSiteInformationError = this.loadSiteInformationError.bind(this);
    this.loadSiteInformationSuccess = this.loadSiteInformationSuccess.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    if (window.location.pathname === '/') {
      this.loadSiteInformation();
    } else {
      window.location.href = `${window.location.origin}/#404`;
    }
  }

  getTokenError() {
    if (Cookies.get('token')) {
      this.setState({ authenticated: true });
    } else {
      this.login();
    }
  }

  getTokenSuccess(user) {
    const {
      routeProps: {
        history: { replace }
      }
    } = this.props;

    if (!user.accessToken) {
      throw new Error('There is no token set in the URL');
    }

    Cookies.set('token', user.accessToken, { expires: parseInt(user.data.expires_in) });

    this.setState({ authenticated: true });

    // Redirect to the URL that was originally requested.
    replace(JSON.parse(atob(user.data.state)).from);
  }

  handleUnauthorisedRequest({ message: { detail } }) {
    // We have to rely on the API response content to determine if the request was unauthorised
    // or unauthenticated because the http status is 403 for both cases. Unauthenticated requests
    // will have the following response: { detail: "Authentication credentials were not provided." }
    if (detail === 'Authentication credentials were not provided.') {
      this.login();
    } else {
      this.setState({ permissionError: true });
    }
  }

  initiateAuth(schoolId) {
    const oΑuthState = Cookies.get('state');

    this.authInstance = new ClientOauth2({
      authorizationUri: getAuthURL(schoolId),
      clientId: AUTH_CLIENT_ID,
      redirectUri: `${window.location.origin}/`,
      scopes: ['read', 'write']
    });

    Cookies.expire('state');

    // Try to get the token from the current URL.
    return this.authInstance.token
      .getToken(window.location.href, {
        state: oΑuthState
      })
      .then(this.getTokenSuccess)
      .catch(this.getTokenError);
  }

  loadSiteInformation() {
    return fetchWrapper({
      credentials: 'omit',
      crossOrigin: true,
      errorCallback: this.loadSiteInformationError,
      method: 'get',
      successCallback: this.loadSiteInformationSuccess,
      url: API_SITE_INFORMATION
    });
  }

  loadSiteInformationSuccess({
    externalSchoolId,
    misEnabled: hasMISIntegration,
    siteId,
    siteName
  }) {
    this.initiateAuth(externalSchoolId);

    this.setState({ hasMISIntegration, siteId, siteName });
  }

  loadSiteInformationError(error) {
    handleError(error);

    this.setState({ unauthenticatedError: true });
  }

  login() {
    const {
      routeProps: {
        location: { pathname }
      }
    } = this.props;
    const oAuthState = btoa(JSON.stringify({ from: pathname }));

    Cookies.set('state', oAuthState);

    window.open(this.authInstance.token.getUri({ state: oAuthState }), '_self');
  }

  logout() {
    const payload = {
      // eslint-disable-next-line camelcase
      client_id: AUTH_CLIENT_ID,
      token: Cookies.get('token')
    };

    return fetchWrapper({
      contentType: 'application/x-www-form-urlencoded',
      crossOrigin: true,
      errorCallback: () => this.setState({ logoutError: true }),
      method: 'post',
      payload,
      successCallback: () => {
        Cookies.expire('token');
        window.open(LOGOUT, '_self');
      },
      url: REVOKE_TOKEN_URL
    });
  }

  render() {
    const { routeProps } = this.props;
    const {
      authenticated,
      hasMISIntegration,
      logoutError,
      permissionError,
      siteId,
      siteName,
      unauthenticatedError
    } = this.state;

    if (!authenticated) {
      if (unauthenticatedError) {
        return (
          <InformationPage title="Failed to fetch school information">
            Please make sure you have typed the correct URL. If the problem persists, please contact
            Primarysite on 01636 616630 for assistance.
          </InformationPage>
        );
      }

      return null;
    }

    return permissionError ? (
      <InformationPage title="Access denied">
        You do not have the appropriate permissions to access this page.
        <Link extraStyles={styles.homepageLink} url={WEBSITE}>
          Back to home page
        </Link>
      </InformationPage>
    ) : (
      <Switch>
        <Route
          path="/logout"
          render={() => {
            this.logout();

            return (
              <InformationPage title={logoutError ? 'Failed to log out' : siteName}>
                {logoutError
                  ? 'Your request cannot be fulfilled at this time. If the problem persists, ' +
                    'please contact Primarysite on 01636 616630 for assistance.'
                  : 'Logging out…'}
              </InformationPage>
            );
          }}
        />
        <Route
          path="/"
          render={() => (
            <AppShell
              handleUnauthorisedRequest={this.handleUnauthorisedRequest}
              hasMISIntegration={hasMISIntegration}
              siteId={siteId}
              siteName={siteName}
              {...routeProps}
            />
          )}
        />
      </Switch>
    );
  }
}

PrivateRoute.propTypes = {
  routeProps: PropTypes.shape({
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired
    }).isRequired,
    match: PropTypes.shape({
      isExact: PropTypes.bool.isRequired,
      path: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};
